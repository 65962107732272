<template>
  <div class="clinicView">
    <vs-card id="editPage">

      <div slot="header" class="flex items-center justify-end space-x-2 w-full">
        <vs-button @click="getAccountLogs" class="flex-1 lg:flex-none bg-green-light text-primary hover:text-primary" type="filled"> View Logs </vs-button>
        <vs-button @click="saveData" icon-pack="feather" class="flex-1 lg:flex-none">Save Details</vs-button>
      </div>

      <h5>Organization Details</h5>
      <div class="w-full lg:w-1/2 my-5 flex flex-col lg:flex-row flex-no-wrap items-end space-x-4">
          <vs-input
            :success="
              !errors.first('basic.name') && organizationDetail.name != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.name') ? true : false"
            v-validate="'required'"
            name="organizationName"
            :danger-text="errors.first('basic.name')"
            data-vv-as="ABN"
            label="Organization Name"
            class="w-full"
            data-vv-scope="basic"
            v-model="organizationDetail.name"
            disabled
          />
        <div class="w-full lg:w-auto flex items-center space-x-2 pb-2">
            <change-org
              :clinicId="clinicId"
              :clinicData="clinicData"
              :currentOrg="organizationDetail._id"
              :updater="updater"
              @refetchClinic="getClinicDetail"
            />
            <vs-button
              type="flat"
              icon-pack="feather"
              icon="icon-external-link"
              icon-after
              @click="openOrganization"
            >
              View
            </vs-button>
        </div>
      </div>

      <vs-divider class="my-8"></vs-divider>

      <h5>Clinic Details</h5>

      <div class="grid grid-cols lg:grid-cols-2 gap-x-8 gap-y-4 mt-5">
        <div class="w-full">
          <vs-input
            :success="
              !errors.first('basic.clinicName') && clinicData.clinicName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.clinicName') ? true : false"
            v-validate="'required'"
            name="clinicName"
            :danger-text="errors.first('basic.clinicName')"
            data-vv-as="clinic name"
            label="Clinic Name"
            class="w-full"
            data-vv-scope="basic"
            v-model="clinicData.clinicName"
          />
        </div>

        <div class="w-full">
          <vs-input
            :danger="errors.first('bestContactNumber') ? true : false"
            :success="
              !errors.first('bestContactNumber') && clinicData.bestContactNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            name="phoneNumber"
            label="Phone Number (Optional)"
            v-model="clinicData.bestContactNumber"
            class="w-full"
          />
          <span class="text-danger text-sm error-text">{{
            errors.first("bestContactNumber")
          }}</span>
        </div>

        <div class="w-full">
          <label>Clinic Address</label>
          <div id="geocoder"></div>
        </div>

        <div class="w-full">
          <vs-input
            :success="
              !errors.first('basic.city') &&
              clinicData.address && clinicData.address.suburb != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.city') ? true : false"
            name="city"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.city')"
            data-vv-as="City"
            label="City"
            class="w-full"
            v-model="clinicData.address.suburb"
          />
        </div>

        <div class="w-full">
          <vs-input
            :success="
              !errors.first('basic.state') &&
              clinicData.address && clinicData.address.state != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.state') ? true : false"
            name="state"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.state')"
            data-vv-as="State"
            label="State"
            class="w-full"
            v-model="clinicData.address.state"
          />
        </div>

        <div class="w-full">
          <vs-input
            :success="
              !errors.first('basic.postCode') &&
              clinicData.address && clinicData.address.postCode != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.postCode') ? true : false"
            name="postCode"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.postCode')"
            data-vv-as="postCode"
            label="Zipcode"
            class="w-full"
            v-model="clinicData.address.postCode"
          />
        </div>

        <div class="w-full">
          <vs-input
            :success="
              !errors.first('basic.postalAddress') &&
              clinicData.address && clinicData.postalAddress != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.postalAddress') ? true : false"
            name="postalAddress"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.postalAddress')"
            data-vv-as="postal address"
            label="Clinic Postal Address (Optional)"
            class="w-full"
            v-model="clinicData.postalAddress"
          />
        </div>

        <div class="w-full flex items-center space-x-6">
          <div>
            <label for="admin_approval">Admin Approval</label>
            <vs-switch
              id="admin_approval"
              class="my-1"
              @click="changeAdminApproval(clinicData.hasApprovedByAdmin)"
              v-model="adminToggle"
            />
          </div>

          <div>
            <label for="clinic_status">Clinic Status</label>
            <div class="flex items-center">
              <vs-switch
                @click="changeStatus(statusToggle)"
                id="clinic_status"
                class="my-1 mr-2"
                v-model="statusToggle"
              />
              <p>({{ statusToggle ? 'Active' : 'Inactive' }})  </p>
            </div>
          </div>

        </div>

        <div class="w-full">
          <vs-input
            :success-text="sageSuccess"
            :success="sageSuccess ? true : false"
            :danger-text="sageError"
            :danger="sageError ? true : false"
            val-icon-success="done"
            val-icon-danger="error"
            name="sageClientId"
            data-vv-scope="basic"
            data-vv-as="Sage Client ID"
            label="Sage Client ID"
            class="w-full"
            readonly
            v-model="clinicData.sageClientId"
          />
        </div>
      </div>

      <div id="resetPasswordContainer">
        <vs-card v-if="resetPassword == true">
          <h4 class="mb-2 ml-5 mt-2">Reset Password</h4>
          <form autocomplete="off">
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              vs-w="12"
            >
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-input
                    :success="
                      !errors.first('resetPassword.newPassword') && password != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="
                      errors.first('resetPassword.newPassword') ? true : false
                    "
                    name="newPassword"
                    :danger-text="errors.first('resetPassword.newPassword')"
                    label="New Password"
                    class="w-full"
                    v-validate="{
                      required: true,
                      min: 6,
                      regex:
                        /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                    }"
                    data-vv-as="new password"
                    data-vv-scope="resetPassword"
                    ref="password"
                    icon-no-border
                    icon-pack="feather"
                    v-model="password"
                    :icon="passwordIcon"
                    :type="passwordType"
                    @click.native="showHidePassword($event)"
                  />
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-input
                    label="Confirm Password"
                    :success="
                      !errors.first('resetPassword.confirmPassword') &&
                      confirmPassword != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="
                      errors.first('resetPassword.confirmPassword') ? true : false
                    "
                    name="confirmPassword"
                    :danger-text="errors.first('resetPassword.confirmPassword')"
                    v-validate="'required|confirmed:password'"
                    data-vv-as="confirm password"
                    data-vv-scope="resetPassword"
                    class="w-full"
                    icon-no-border
                    icon-pack="feather"
                    v-model="confirmPassword"
                    :icon="confirmPasswordIcon"
                    :type="confirmPasswordType"
                    @click.native="showHideConfirmPassword($event)"
                  />
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-button
                    size="lg"
                    @click="changePassword"
                    icon-pack="feather"
                    class="mr-2"
                    >Reset Password</vs-button
                  >
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5" align="right">
                  <vs-button
                    @click="resetPassword = false"
                    color="danger"
                    icon-pack="feather"
                    class="mr-2"
                    >Cancel</vs-button
                  >
                </div>
              </vs-col>
            </vs-row>
          </form>
        </vs-card>
      </div>
    </vs-card>
    <LogsSidebar :activity_logs="activityLogs" ref="logsSidebar" />
    <oversight-products
      v-if="$isUSRegion()"
      :isFetching="isFetching"
      :clinicId="clinicId"
      :selectedProducts="selectedProducts"
      :packages="tierPackages"
      :details="clinicData.tierPackage"
      @handleUpdateTierPackage="(pkg) => clinicData.tierPackage = pkg"
      @handleSave="(selected) => onSelectProducts(selected)"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import axios from "@/axios";

import ClinicOpeningHours from "./ClinicOpeningHours.vue";
import ClinicPhotos from "./ClinicPhotos";
import { EventBus } from "../../../main";
import vSelect from "vue-select";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import changeOrg from "@/views/components/others/changeOrganisation.vue"
import { updatedFieldChecker } from "../../../utils/updatedFieldChecker";
import LogsSidebar from "@/views/components/account-creation-logs/logs.vue"
import OversightProducts from "./ClinicOversightProducts.vue"

const dict = {
  custom: {
    bestContactNumber: {
      required: "Please enter your best contact number",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The password confirmation does not match",
    },
  },
};

Validator.localize("en", dict);

export default {
  name: "clinicEditDetail",
  inject: ["$validator"],
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic",
    },
    organizationDetailRoute: {
      type: String,
      default: "SuperAdminOrganizationDetails",
    },
  },

  components: {
    VerifyEmail,
    ClinicOpeningHours,
    ClinicPhotos,
    vSelect,
    "change-org": changeOrg,
    LogsSidebar,
    OversightProducts
  },
  data: () => ({
    changeOrgPopup: false,
    organizationDetail: {},
    isRequired: true,
    discounts: [],
    suppliers: [],
    validationKeys: {
      showIsResidentialAddressError: false,
      showIntendToSecureS4MedicationError: false,
      showHaveAdrenalineAndHyalaseInClinicError: false,
    },
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    discountEnableChangeClick: false,
    clinicId: "",
    password: "",
    confirmPassword: "",
    popUpDelete: false,
    clinicData: {
      isProfileCompleted: false,
      clinicAssignedSuppliers: [],
      clinicDiscounts: [],
      firstName: "",
      lastName: "",
      companyName: "",
      clinicName: "",
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
      postalAddress: "",
      bestContactName: "",
      bestContactNumber: "",
      bestContactEmail: "",
      billingEmail: "",
      invoiceEmail: "",
      clinicProductDiscountId: null,
      isAccessPaymentTerms: false,
      isEnableProductDiscount: false,
      status: "",
      hasApprovedByAdmin: false,
      emailVerifiedAt: null,
      contactNumber: null,
      instagram: "",
      facebook: "",
      website: "",
      clinicAddress1: null,
      isResidentialAddress: null,
      haveAdrenalineAndHyalaseInClinic: null,
      intendToSecureS4Medication: null,
      abnCertificate: null,
      councilPermits: null,
      poisonsCertificate: null,
      clinicOpeningHours: {
        mondays: "",
        tuesdays: "",
        wednesdays: "",
        thursdays: "",
        fridays: "",
        saturdays: "",
        sundays: "",
      },
      clinicFrontPhoto: null,
      clinicFloorPhoto: null,
      clinicTreatmentBedPhoto: null,
      clinicDrawingUpAreaPhoto: null,
      clinicFridgePhoto: null,
      clinicDrugStoragePhoto: null,
      clinicBinsPhoto: null,
      clinicRoomLockPhoto: null,
      clinicEpipenAndHyalasePhoto: null,
      removedImages: null,
      sageClientId: "",
      isTrainingClinic: null,
      isStaffClinic: null,
      isPOSEnabled: null,
      isInventoryEnabled: null,
      isCreditCardFeeExempt: null,
      isTaxable: null,
      isTestClinic: null,
      showStore: null,
      tierPackage: {
        id: null,
        gfeConsultationFee: null,
        name: ""
      }
    },
    resetPassword: false,
    checkDisplayAddress: "",
    addressData: [],
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
    region: process.env.VUE_APP_REGION,
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    sageError:'',
    sageSuccess:'',
    updater: null,
    oldData: null,
    oldAddress: null,
    active: false,
    activityLogs: null,
    adminToggle: false,
    statusToggle: false,
    selectedProducts: [],
    tierPackages: [],
    isFetching: true
  }),
  methods: {
    ...mapActions("clinic", [
      "fetchClinicsDetail",
      "updateClinicDetail",
      "resetClinicPassword",
      "deleteClinic",
      "removeClinicImages",
    ]),
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("storeProducts", ["fetchSuppliers"]),
    ...mapActions("appUser", ["getAccountCreationLogs"]),
    ...mapActions("tierPackage", [
      "listTierPackage"
    ]),
    async getTierPackages() {
      this.isFetching = true
      try {
        const res = await this.listTierPackage({
          limit: 100
        })

        if (res.data && res.data.data) {
          const formattedTierPackages = (res.data.data.docs || []).map((pkg) => ({
            id: pkg._id,
            name: pkg.name,
            gfeConsultationFee: pkg.gfeConsultationFee / 100,
            products: pkg.products || []
          }))

          this.tierPackages = formattedTierPackages
        }
      } catch (err) {
        console.log('error fetching tier packages:: ', err)
      } finally {
        this.isFetching = false
      }
    },
    resetPasswordHandler() {
      this.resetPassword = true;
      window.scrollTo(0, document.body.scrollHeight);
    },
    async changePassword() {
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetClinicPassword({
          id: this.clinicId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          this.password = "";
          this.confirmPassword = "";
          this.$validator.reset();
          this.$vs.notify({
            title: "Clinic password reset",
            text: "Clinic's password reset Successfully.",
            color: "success",
          });
        });
      }
    },
    openOrganization() {
      this.$router.push({
        name: this.organizationDetailRoute,
        params: { organizationId: this.organizationDetail._id },
      });
    },
    closeOrganization() {
      this.changeOrgPopup = false;
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            this.clinicData.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    removeImage(imageUrl, selector, loadingClass) {
      this.clinicData[loadingClass] = this.clinicData[loadingClass].filter(
        (value) => value.photoUrl != imageUrl
      );

      this.clinicData.removedImages.push(imageUrl);
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async getClinicDetail(id) {
      await this.fetchClinicsDetail(id)
        .then((res) => {
          const clinic = res.data.data;

          if (clinic.products && clinic.products.length) {
            this.selectedProducts = clinic.products.map(
              ({ productId }) => productId
            );
          }

          this.organizationDetail = { ...clinic.organizationDetail };
          this.clinicId = clinic._id;
          this.clinicData.firstName = clinic.firstName;
          this.clinicData.lastName = clinic.lastName;
          this.clinicData.companyName = clinic.companyName;
          this.clinicData.clinicName = clinic.clinicName;
          this.clinicData.abn = clinic.abn;
          this.clinicData.streetAddress = clinic.address
            ? clinic.address.displayAddress
            : "";
          this.clinicData.postalAddress = clinic.postalAddress;
          this.clinicData.bestContactName = clinic.bestContactName;
          this.clinicData.bestContactNumber = clinic.bestContactNumber;
          this.clinicData.bestContactEmail = clinic.bestContactEmail;
          this.clinicData.billingEmail = clinic.billingEmail;
          this.clinicData.invoiceEmail = clinic.invoiceEmail;
          this.clinicData.isAccessPaymentTerms =
            typeof clinic.isAccessPaymentTerms !== "undefined"
              ? clinic.isAccessPaymentTerms
              : false;
          this.clinicData.isBYODoctor =
            typeof clinic.isBYODoctor !== "undefined"
              ? clinic.isBYODoctor
              : false;
          this.clinicData.isEnableProductDiscount =
            typeof clinic.isEnableProductDiscount !== "undefined"
              ? clinic.isEnableProductDiscount
              : false;
          this.clinicData.clinicProductDiscountId =
            clinic.clinicProductDiscountId
              ? clinic.clinicProductDiscountId
              : null;
          this.clinicData.clinicAssignedSuppliers =
            clinic.clinicAssignedSuppliers
              ? clinic.clinicAssignedSuppliers
              : [];
          this.clinicData.clinicDiscounts =
            clinic.clinicDiscounts
              ? clinic.clinicDiscounts
              : [];
          this.clinicData.address = clinic.address;
          this.checkDisplayAddress = clinic.address ? clinic.address.displayAddress : null;
          this.clinicData.hasApprovedByAdmin = clinic.hasApprovedByAdmin ? clinic.hasApprovedByAdmin : false;
          this.clinicData.emailVerifiedAt = clinic.emailVerifiedAt;
          this.clinicData.email = clinic.email;
          this.clinicData.bestContactNumber = clinic.bestContactNumber
            ? clinic.bestContactNumber
            : "";
          this.clinicData.instagram = clinic.instagram ? clinic.instagram : "";
          this.clinicData.facebook = clinic.facebook ? clinic.facebook : "";
          this.clinicData.website = clinic.website ? clinic.website : "";
          this.clinicData.clinicAddress1 = clinic.clinicAddress1
            ? clinic.clinicAddress1
            : null;
          this.clinicData.isResidentialAddress = clinic.isResidentialAddress
            ? clinic.isResidentialAddress
            : null;
          this.clinicData.haveAdrenalineAndHyalaseInClinic =
            clinic.haveAdrenalineAndHyalaseInClinic
              ? clinic.haveAdrenalineAndHyalaseInClinic
              : null;
          this.clinicData.intendToSecureS4Medication =
            clinic.intendToSecureS4Medication
              ? clinic.intendToSecureS4Medication
              : null;
          this.clinicData.abnCertificate = clinic.abnCertificate
            ? clinic.abnCertificate
            : null;
          this.clinicData.councilPermits = clinic.councilPermits
            ? clinic.councilPermits
            : null;
          this.clinicData.poisonsCertificate = clinic.poisonsCertificate
            ? clinic.poisonsCertificate
            : null;
          this.clinicData.clinicOpeningHours = clinic.clinicOpeningHours
            ? clinic.clinicOpeningHours
            : {
                mondays: "",
                tuesdays: "",
                wednesdays: "",
                thursdays: "",
                fridays: "",
                saturdays: "",
                sundays: "",
              };
          this.clinicData.clinicFrontPhoto = clinic.clinicFrontPhoto
            ? clinic.clinicFrontPhoto
            : null;
          this.clinicData.clinicFloorPhoto = clinic.clinicFloorPhoto
            ? clinic.clinicFloorPhoto
            : null;
          this.clinicData.clinicTreatmentBedPhoto =
            clinic.clinicTreatmentBedPhoto
              ? clinic.clinicTreatmentBedPhoto
              : null;
          this.clinicData.clinicDrawingUpAreaPhoto =
            clinic.clinicDrawingUpAreaPhoto
              ? clinic.clinicDrawingUpAreaPhoto
              : null;
          this.clinicData.clinicFridgePhoto = clinic.clinicFridgePhoto
            ? clinic.clinicFridgePhoto
            : null;
          this.clinicData.clinicDrugStoragePhoto = clinic.clinicDrugStoragePhoto
            ? clinic.clinicDrugStoragePhoto
            : null;
          this.clinicData.clinicBinsPhoto = clinic.clinicBinsPhoto
            ? clinic.clinicBinsPhoto
            : null;
          this.clinicData.clinicRoomLockPhoto = clinic.clinicRoomLockPhoto
            ? clinic.clinicRoomLockPhoto
            : null;
          this.clinicData.clinicEpipenAndHyalasePhoto =
            clinic.clinicEpipenAndHyalasePhoto
              ? clinic.clinicEpipenAndHyalasePhoto
              : null;
          this.clinicData.isProfileCompleted =
            clinic.isProfileCompleted || false;
          this.clinicData.isTrainingClinic = clinic.isTrainingClinic
            ? clinic.isTrainingClinic : null;
          this.clinicData.isStaffClinic = clinic.isStaffClinic
            ? clinic.isStaffClinic : null;
          this.clinicData.isDrugBookEnabled = clinic.isDrugBookEnabled
            ? clinic.isDrugBookEnabled
            : null;
          this.clinicData.isPOSEnabled = clinic.isPOSEnabled
            ? clinic.isPOSEnabled
            : null;
          this.clinicData.isInventoryEnabled = clinic.isInventoryEnabled
            ? clinic.isInventoryEnabled
            : null;
          this.clinicData.isCreditCardFeeExempt = clinic.isCreditCardFeeExempt
            ? clinic.isCreditCardFeeExempt
            : null;
          this.clinicData.isTaxable = clinic.isTaxable
            ? clinic.isTaxable
            : null;
          this.clinicData.isTestClinic = clinic.isTestClinic
            ? clinic.isTestClinic
            : null;
          this.clinicData.showStore = clinic.showStore ? clinic.showStore : null;
          this.clinicData.paymentTypes = clinic.paymentTypes
            ? clinic.paymentTypes
            : null;
          this.clinicData.orderRecipients = clinic.orderRecipients
            ? clinic.orderRecipients.join(",")
            : [];
          this.clinicData.sageClientId = clinic.sageClientId;
          this.clinicData.status = clinic.status == 'Active' ? false : true;
          this.clinicData.oversightProducts = clinic.products
            ? clinic.products.map(({ productName }) => productName).join(", ")
            : "";

          this.clinicData.storeInvoiceEmail = clinic.storeInvoiceEmail
          this.clinicData.isDoctorStore = clinic.isDoctorStore

          this.oldAddress = _.cloneDeep(clinic.address);
          this.oldData = _.cloneDeep(this.clinicData);

          this.oldData.hasApprovedByAdmin = clinic.hasApprovedByAdmin ? clinic.hasApprovedByAdmin : false;
          this.oldData.orderRecipients = clinic.orderRecipients ? clinic.orderRecipients.join(",") : [];
          this.oldData.status = clinic.status 
          this.oldData.oversightProducts = clinic.products ? clinic.products.map(({ productName }) => productName).join(", ") : "";

          this.adminToggle = clinic.hasApprovedByAdmin ? clinic.hasApprovedByAdmin : false;
          this.statusToggle = clinic.status == 'Active' ? true : false;

          if (clinic.tierPackage) {
            this.clinicData.tierPackage.id = clinic.tierPackage.id ? clinic.tierPackage.id : null
            this.clinicData.tierPackage.gfeConsultationFee = clinic.tierPackage.gfeConsultationFee ? clinic.tierPackage.gfeConsultationFee : null
          }
          if (clinic.tierPackageInfo) {
            this.clinicData.tierPackage.name = clinic.tierPackageInfo.name ? clinic.tierPackageInfo.name : ""
          }

          return this.fetchSuppliers();
        })
        .then((suppliers) => {
          this.suppliers = suppliers.data.data;
          this.getSageCustomerInfo();
        });
    },
    async getDiscountList() {
      await this.fetchDiscountList().then((response) => {
        this.discounts = response.data.data;
      });
    },
    onSuccessfullyVerified(verifiedAt) {
      this.clinicData.emailVerifiedAt = verifiedAt;
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");

      if (
        this.$store.state.AppActiveUser.userType !== "superAdmin" &&
        this.$store.state.AppActiveUser.userType !== "admin" &&
        this.$store.state.AppActiveUser.userType !== "franchise" &&
        this.$store.state.AppActiveUser.userType !== "subFranchise"
      ) {
        isValid = await this.$validator.validate();
        isValid = await this.$refs.stepTwoRef.$validator.validate();
        isValid = await this.$refs.stepThreeRef.$validator.validate();
        isValid = await this.$refs.step4Ref.$validator.validate();
        isValid = await this.$refs.step5Ref.$validator.validate();
        isValid = await this.$refs.step6Ref.$validator.validate();
        isValid = await this.$refs.step7Ref.$validator.validate();
        isValid = await this.$refs.step8Ref.$validator.validate();
        isValid = await this.$refs.step9Ref.$validator.validate();
        isValid = await this.$refs.step10Ref.$validator.validate();
        isValid = await this.$refs.step11Ref.$validator.validate();

        if (this.clinicData.isResidentialAddress === "") {
          isValid = false;
          this.validationKeys.showIsResidentialAddressError = true;
        } else {
          this.validationKeys.showIsResidentialAddressError = false;
        }

        if (this.clinicData.intendToSecureS4Medication === "") {
          isValid = false;
          this.validationKeys.showIntendToSecureS4MedicationError = true;
        } else {
          this.validationKeys.showIntendToSecureS4MedicationError = false;
        }

        if (this.clinicData.haveAdrenalineAndHyalaseInClinic === "") {
          isValid = false;
          this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = true;
        } else {
          this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
        }

        if (this.clinicData.abnCertificate == "") {
          this.errors.add({
            field: "abnCertificate",
            msg: "ABN Certificate is required",
          });
          document.getElementById("abnCertificateId").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("abnCertificateId").style.borderColor =
            "#cccccc";
          this.errors.remove("abnCertificate");
        }

        if (this.clinicData.clinicFrontPhoto.length === 0) {
          this.$refs.stepThreeRef.errors.add({
            field: "clinicFrontPhoto",
            msg: "Clinic Front Photo is required",
          });
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepThreeRef.errors.remove("clinicFrontPhoto");
        }

        if (this.clinicData.clinicFloorPhoto.length === 0) {
          this.$refs.step4Ref.errors.add({
            field: "clinicFloorPhoto",
            msg: "Clinic Floor Photo is required",
          });
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step4Ref.errors.remove("clinicFloorPhoto");
        }

        if (this.clinicData.clinicTreatmentBedPhoto.length === 0) {
          this.$refs.step5Ref.errors.add({
            field: "clinicTreatmentBedPhoto",
            msg: "Clinic Treatment Bed Photo is required",
          });
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step5Ref.errors.remove("clinicTreatmentBedPhoto");
        }

        if (this.clinicData.clinicDrawingUpAreaPhoto.length === 0) {
          this.$refs.step6Ref.errors.add({
            field: "clinicDrawingUpAreaPhoto",
            msg: "Clinic Drawing Up Area Photo is required",
          });
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#ea5455";
          isValid = false;
        } else {
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#cccccc";
          this.$refs.step6Ref.errors.remove("clinicDrawingUpAreaPhoto");
        }

        if (this.clinicData.clinicFridgePhoto.length === 0) {
          this.$refs.step7Ref.errors.add({
            field: "clinicFridgePhoto",
            msg: "Clinic Fridge Photo is required",
          });
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#cccccc";
          this.$refs.step7Ref.errors.remove("clinicFridgePhoto");
        }

        if (this.clinicData.clinicDrugStoragePhoto.length === 0) {
          this.$refs.step8Ref.errors.add({
            field: "clinicDrugStoragePhoto",
            msg: "Clinic Drug Storage Photo is required",
          });
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#cccccc";
          this.$refs.step8Ref.errors.remove("clinicDrugStoragePhoto");
        }

        if (this.clinicData.clinicBinsPhoto.length === 0) {
          this.$refs.step9Ref.errors.add({
            field: "clinicBinsPhoto",
            msg: "Clinic Bins Photo is required",
          });
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step9Ref.errors.remove("clinicBinsPhoto");
        }
      }

      if (isValid) {
        this.$vs.loading();
        this.clinicData.clinicId = this.clinicId,
        this.clinicData.updater = `${this.updater.firstName} ${this.updater.lastName}`,
        this.clinicData.name = this.clinicData.clinicName,
        this.clinicData.updatedFields = updatedFieldChecker(this.oldData, this.clinicData)

        if(this.oldData.hasApprovedByAdmin !== this.clinicData.hasApprovedByAdmin) {
          this.clinicData.updatedFields = {
            ...this.clinicData.updatedFields,
            hasApprovedByAdmin: {
              oldValue: this.oldData.hasApprovedByAdmin, newValue: this.clinicData.hasApprovedByAdmin
            }
          }
        }

        if(this.oldData.status !== (this.clinicData.status ? 'Disabled' : 'Active')) {
          this.clinicData.updatedFields = {
            ...this.clinicData.updatedFields,
            status: {
              oldValue: this.clinicData.status ? 'Active' : 'Inactive', newValue: this.clinicData.status ? 'Inactive' : 'Active'
            }
          }
        }


        await this.updateClinicDetail({
          id: this.clinicId,
          data: {
            ...this.clinicData,
            products: this.selectedProducts
          }
        }).then((res) => {
            this.$vs.notify({
              title: "Success",
              text: "Clinic updated successfully.",
              color: "success",
            });
            this.getClinicDetail(this.clinicId);
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update clinic. Try again!",
              color: "danger",
            });

            if (err.status === 422) {
              this.mapServerErrors(this, err, "basic");
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                this.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
              } else if (err.data.title === "Invalid ABN") {
                this.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
              }
            }
            this.$vs.loading.close();
          });
      }
    },
    async changeAdminApproval(status) {
      this.oldData.hasApprovedByAdmin =  this.clinicData.hasApprovedByAdmin
      this.clinicData.hasApprovedByAdmin = !status
      this.adminToggle = status
    },
    async changeStatus(status) {
      this.clinicData.status = status
      this.statusToggle = status
    },
    async changeDiscountEnable(status) {
      this.discountEnableChangeClick = true;
      await this.updateClinicDetail({
        id: this.clinicId,
        data: { isEnableProductDiscount: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Discount enable updated successfully.",
            color: "success",
          });
          this.discountEnableChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update discount enable. Try again!",
            color: "danger",
          });
          this.discountEnableChangeClick = false;
        });
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    regionType() {
      const regions = {
        US: "us",
        AU: "au",
        NZ: "nz"
      }
      return regions[this.region]
    },
    async getSageCustomerInfo() {
        try {
            if (!this.clinicData.sageClientId) {
                this.sageSuccess = ''
                this.sageError = ''
                return
            }

            this.$vs.loading();

            const {data} = await axios.get(`/sage/customer/${this.clinicData.sageClientId}`)

            if (data && data.data && data.data.NAME) {
                this.sageSuccess = `Customer ID ${this.clinicData.sageClientId} corresponds to an entity named ${data.data.NAME} with status ${data.data.STATUS} on the Sage platform.`;
                this.sageError = '';
            } else {
                this.sageSuccess = ''
                this.sageError = `The customer with ID ${this.clinicData.sageClientId} does not exist on the Sage platform.`
            }
        } catch (e) {
            this.sageSuccess = ''
            this.sageError = `The customer with ID ${this.clinicData.sageClientId} does not exist on the Sage platform.`
        } finally {
            this.$vs.loading.close();
        }
    },
    async validateSageCustomer() {
        if (this.timeout)
            clearTimeout(this.timeout);

        this.timeout = setTimeout(async () => {
            this.getSageCustomerInfo()
        }, 1000); // delay
    },
    async getAccountLogs() {
      this.$refs.logsSidebar.openSidebar();

      await this.getAccountCreationLogs({
        id: this.$route.params.clinicId, 
        type: 'clinic',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
        .then(res => {
         this.activityLogs = res

        }).catch(err => {
          console.warn(err)
        })
    },
    onSelectProducts(selectedProducts) {
      this.selectedProducts = selectedProducts.map(({ _id }) => _id);
      this.clinicData.oversightProducts = selectedProducts && selectedProducts.length ? selectedProducts.map(({ name }) => name).join(", ") : "";
    }
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: this.regionType() } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.clinicData.address.displayAddress = place.formatted_address;
      this.clinicData.address.geometry.coordinates[0] = lon;
      this.clinicData.address.geometry.coordinates[1] = lat;

      this.clinicData.address.suburb = "";
      this.clinicData.address.state = "";
      this.clinicData.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            this.clinicData.address.suburb = gAdd.long_name;
            this.errors.remove("clinicSuburb");
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            this.clinicData.address.state = gAdd.long_name;
            this.errors.remove("clinicState");
          }
          if (gAdd.types[0] === "postal_code") {
            this.clinicData.address.postCode = gAdd.long_name;
            this.errors.remove("clinicPostCode");
          }
        });
      }
    });
  },
  async created() {
    this.updater = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );

    let clinicId = this.$route.params.clinicId;
    await this.getClinicDetail(clinicId);
    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
      this.$store.state.AppActiveUser.userType === "admin" ||
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
    await this.getDiscountList();
    mapboxgl.accessToken = this.accessToken;

    await this.getTierPackages();

    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: 'address',
      countries: this.regionType(),
      placeholder: this.clinicData.address ? this.clinicData.address.displayAddress : null
    });

    geocoder.addTo('#geocoder');

    geocoder.on('results', (e) => {
      this.clinicData.address.displayAddress = e.config.query;
      this.clinicData.address.suburb = '';
      this.clinicData.address.state = '';
      this.clinicData.address.postCode = '';
      this.clinicData.address.coordinates = {
        longitude: '',
        latitude: ''
      };
    })

    geocoder.on('result', (e) => {
      const regionType = this.region === 'US' ? 'district' : 'locality';

      const suburbItem = e.result.context.find(function(item) {
        return item.id.includes(regionType);
      });
      this.clinicData.address.suburb = suburbItem ? suburbItem.text : '';

      const stateItem = e.result.context.find(function(item) {
        return item.id.includes('region');
      });
      this.clinicData.address.state = stateItem ? stateItem.text : '';

      const postcodeItem = e.result.context.find(function(item) {
        return item.id.includes('postcode');
      });
      this.clinicData.address.postCode = postcodeItem ? postcodeItem.text : '';

      this.clinicData.address.displayAddress = e.result.place_name;

      this.clinicData.address.coordinates = {
        longitude: e.result.center[0],
        latitude: e.result.center[1]
      };
    });
  },
  watch: {
    "clinicData.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIsResidentialAddressError = false;
      }
    },
    "clinicData.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIntendToSecureS4MedicationError = false;
      }
    },
    "clinicData.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.$emit("closePopup");
  },
};
</script>

<style lang="scss">
  .sidebarx {
    .vs-sidebar {
      max-width: 400px;

      &--items {
        overflow-y: auto;
        overflow-x: hidden !important;
      }
    }
  }
  .mapboxgl-ctrl-geocoder {
    width: 100%!important;
    max-width: unset !important;
    box-shadow: none;
    border: 1px solid #83cdb8;
  }
</style>

<style scoped lang="scss">
  .error-text {
    font-size: 0.65rem !important;
    padding: 2px 4px 4px;
    display: block;
    margin-top: -6px;
  }

  .no-margin-span {
    margin-top: 0 !important;
  }
</style>
