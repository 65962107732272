<template>
  <div>
    <vs-card>
      <div
        slot="header">
        <div v-if="isFetching"
          class="flex items-center justify-between h-6">
          <div class="animate-pulse w-24">
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
          <div class="animate-pulse w-24">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex items-center justify-between w-full">
          <p class="font-semibold lg:text-lg md:text-lg text-base">
            Clinic Tier Package
          </p>
          <vs-button
            v-if="!!details.id"
            @click="handleSidebar(true)()"
            icon="edit"
            class="lg:flex md:flex"
          >
            Edit Package
          </vs-button>
        </div>
      </div>
      <div class="w-full" v-if="!isFetching">
        <div v-if="!details.id">
          <p class="text-sm mb-5">Select a package below to review the package details and finalize the clinics tiered package setup.</p>
          <div
            v-for="(item, index) in packages"
            :key="index"
            class="flex mt-2 w-1/5 cursor-pointer"
            @click="handleSelectTierPackage(item)"
          >
            <p
              class="product-box py-3 px-4 rounded-lg flex items-center w-full text-sm"
            >
              {{ item.name }} (${{ item.gfeConsultationFee }} per call)
            </p>
          </div>
        </div>
        <div v-else>
          <vs-input
            name="Name"
            label="Assigned Package"
            class="w-1/6"
            readonly
            v-model="details.name"
          />
          <vs-input
            name="Name"
            label="GFE Consultation Fee(Per call)"
            class="w-1/6 mt-4"
            readonly
            v-model="details.gfeConsultationFee"
          />
        </div>
      </div>
      <div v-else
        class="animate-pulse">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 rounded bg-gray-400"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-sidebar
      class="sidebarx oversight-products relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="isOpen"
    >
      <!-- HEADER -->
      <div class="header space-y-3">
        <form autocomplete="off">
          <p class="text-xl font-semibold px-4 pt-4">Clinic Tier Package</p>
          <p class="px-4">
            Setup or manage this clinics tiered package. Apply a template, adjust treatment products, or control the GFE consulation fee.
          </p>
          <div class="px-4 mt-2">
            <label class="flex vs-input--label text-sm">
              Assigned Package&nbsp;
              <vx-tooltip
                text="Selecting a new tier will replace the currently selected product. Please note that you will need to reassign the override products afterward."
                position="top">
                <vs-icon
                  icon="help_outline"
                  size="16px"
                  bg="#0000"
                  round
                ></vs-icon>
              </vx-tooltip>
            </label>
            <vs-select
              name="tierPackageId"
              placeholder="Choose Tier Pacakge"
              autocomplete
              v-model="id"
              @input="handleInputChange">
              <vs-select-item v-for="(item, index) in packages" :key="index" :value="item.id" :text="item.name" />
            </vs-select>
          </div>
          <div class="px-4">
            <vs-input
              type="number"
              name="Name"
              label="GFE Consultation Fee(Per call)"
              class="mt-2"
              v-model="gfeConsultationFee"
            />
          </div>
        </form>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchString"
          @input="searchProducts"
          placeholder="Search products"
          class="w-full pb-4 px-4"
        />
      </div>
      <div class="header pb-2 w-full">
        <div class="flex mx-4 space-x-2">
          <vs-button
            type="border"
            @click="selectAllProducts"
            class="mr-2 flex-grow"
          >
              Select All
          </vs-button>
          <vs-button
            type="border"
            @click="removeSelected"
            class="mr-2
            flex-grow"
          >
            Unselect All
          </vs-button>
        </div>
      </div>
      <!-- BODY -->
      <div class="scrollable-content">
        <div v-for="item in products" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selected"
            :vs-value="item._id"
          >
            <span>
              {{ item.name }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footer flex flex-col gap-3" slot="footer">
        <vs-button @click="handleSave" :disabled="isSaving">
          <vs-icon
            v-if="isSaving"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleSidebar(false)()">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions } from "vuex";

export default {
  props: {
    isFetching: {
      type: Boolean,
      default: () => false,
      required: true
    },
    clinicId: {
      type: String,
      required: true,
      default: () => ""
    },
    selectedProducts: {
      type: Array,
      default: () => [],
      required: true,
    },
    packages: {
      type: Array,
      required: true,
      default: () => []
    },
    details: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        name: "",
        gfeConsultationFee: null
      })
    },
  },
  data() {
    return {
      isOpen: false,
      isSaving: false,
      firstLoad: true,
      searchString: "",
      selected: [],
      tags: [],
      products: [],
      defaultProducts: [],
      id: null,
      gfeConsultationFee: null
    }
  },
  methods: {
    ...mapActions("product", ["fetchClinicOversightProducts"]),
    handleSidebar(isOpen) {
      return () => {
        this.isOpen = isOpen
      }
    },
    selectAllProducts() {
      this.selected = this.products.map(({ _id }) => _id);
    },
    removeSelected() {
      this.selected = [];
    },
    handleSave(isClick = true) {
      this.isSaving = true;

      this.tags = [];

      if (this.selected.length) {
        this.tags = this.defaultProducts.filter(({ _id }) =>
          this.selected.includes(_id)
        );
      }

      if (this.searchString) {
        this.searchString = "";
        this.products = this.getOversightProducts()
      }

      this.isOpen = false;
      this.isSaving = false;

      if (isClick) {
        this.$emit('handleSave', this.tags)

        const pkgInfo = this.packages.find((pkg) => pkg.id === this.id);
        if (pkgInfo) this.$emit('handleUpdateTierPackage', {
          ...pkgInfo,
          gfeConsultationFee: this.gfeConsultationFee
        })
      }
    },
    searchProducts: _.debounce(function (data) {
      this.searchString = data;
      this.getOversightProducts()
    }, 500),
    async getOversightProducts() {
      try {
        const { data } = await this.fetchClinicOversightProducts({
          clinicId: this.clinicId,
          search: this.searchString
        })

        this.products = data.data

        if (this.firstLoad) this.defaultProducts = data.data;

        this.firstLoad = false;
      } catch (err) {
        console.error(err)
      }
    },
    handleSelectTierPackage(pkg) {
      this.id = pkg.id
      this.gfeConsultationFee = pkg.gfeConsultationFee
      this.selected = pkg.products.map(({_id}) => (_id))

      this.isOpen = true
    },
    handleInputChange(data) {
      const pkgInfo = this.packages.find((pkg) => pkg.id === data);

      if (pkgInfo) {
        this.selected = pkgInfo.products.map(({_id}) => (_id))
        this.gfeConsultationFee = pkgInfo.gfeConsultationFee
      }
    },
  },
  watch: {
    clinicId: async function(clinicId) {
      if (clinicId) await this.getOversightProducts()
    },
    selectedProducts: async function(selectedProducts) {
      if (this.clinicId) await this.getOversightProducts()

      if (selectedProducts.length) {
        this.selected = selectedProducts

        this.handleSave(false)
      }
    },
    isOpen: function(isOpen) {
      if (isOpen) {
        this.id = this.details.id
        this.gfeConsultationFee = this.details.gfeConsultationFee
      }
    }
  }
}
</script>

<style lang="scss">
.vs-sidebar--background {
  z-index: 52000 !important;
}

.vs-sidebar.vs-sidebar-position-right {
  z-index: 53000;
}

.oversight-products .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}

.oversight-products .custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.oversight-products .vs-sidebar--items {
  overflow: hidden !important;
}

.oversight-products .vs-sidebar--items .scrollable-content {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: calc(100% - 257px);
}

.oversight-products .vs-sidebar--items .header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.oversight-products .vs-sidebar--footer .footer {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.sidebarx {
  .vs-sidebar {
    max-width: 350px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}

.product-box {
  background: rgba(var(--vs-primary), .08);
  border: 1px solid rgba(var(--vs-primary), 0.3);
}
</style>
